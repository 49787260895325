<template>
  <div
    class="logo-container"
    :class="`${isDark ? 'dark' : ''} ${isScrolled ? 'scrolled' : ''} ${
      isUserLogged ? 'logged' : ''
    } ${darkBackground ? 'dark-background' : ''} ${
      lightBackground ? 'light-background' : ''
    }`"
  >
    <img
      v-if="isUserLogged || (!isUserLogged && isScrolled)"
      class="small-logo"
      :src="
        !isDark && !isScrolled
          ? '/logos/PALC_Marque_Black.svg'
          : '/logos/PALC_Marque_White.svg'
      "
      @click="router.push('/')"
    />
    <img
      v-if="!isScrolled && !isUserLogged"
      class="full-logo"
      :src="isDark ? '/logos/Branding.svg' : '/logos/Branding-black.svg'"
      @click="router.push('/')"
    />
    <UtilsSearch
      v-if="isUserLogged"
      :light-background="lightBackground"
      @showResults="showResults = $event"
    />
    <div
      class="language-selector"
      :class="`${isUserLogged ? 'logged' : ''} ${isScrolled ? 'scrolled' : ''}`"
      v-if="width > 1100"
    >
      <div class="selector">
        <span
          :class="languageSelected === 'es' ? 'body2-underline' : 'body2'"
          @click="$eventBus.$emit('setLocale', 'es'), (languageSelected = 'es')"
        >
          ES</span
        >
        <span class="body2">&nbsp;/&nbsp;</span>
        <span
          :class="languageSelected === 'en' ? 'body2-underline' : 'body2'"
          @click="$eventBus.$emit('setLocale', 'en'), (languageSelected = 'en')"
          >EN</span
        >
      </div>
    </div>
    <div class="login-actions" v-if="width > 1100 && !isUserLogged">
      <div class="actions">
        <span class="body2" @click="router.push('/login')">
          {{ $t('sign_in') }}
        </span>
        <ElButton
          type="secondary"
          :class="isScrolled || isDark ? '' : 'dark'"
          @click="router.push('/auth/register')"
        >
          {{ $t('join') }}
        </ElButton>
      </div>
    </div>
    <div class="donatella-actions" v-if="isUserLogged">
      <ElButton
        type="icon"
        :class="lightBackground ? 'light' : ''"
        @click="router.push('/donatella')"
      >
        <Icons name="24px/message_writing" />
      </ElButton>
    </div>
    <div class="burguer-actions" v-if="width <= 1100 || isUserLogged">
      <Icons
        v-if="width <= 1100 && !isUserLogged"
        :name="
          isScrolled
            ? '24px/menu_white'
            : isDark
            ? '24px/menu_white'
            : '24px/menu'
        "
        @click="showNotLoggedDrawer = true"
      />
      <el-dropdown
        ref="signinDropdown"
        trigger="click"
        :hide-on-click="false"
        popper-class="signin-dropdown"
        :teleported="false"
      >
        <ElButton
          type="icon"
          :class="lightBackground ? 'light' : ''"
          v-if="width > 1100 && isUserLogged"
        >
          <Icons name="24px/menu" />
        </ElButton>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              ><Icons
                name="24px/cross"
                class="icon-close"
                @click="$refs.signinDropdown.handleClose()"
            /></el-dropdown-item>
            <el-dropdown-item
              ><el-collapse accordion v-model="exploreAccordion">
                <el-collapse-item name="1">
                  <template #title>
                    <span class="body1-strong">
                      {{ $t('explore') }}
                    </span>
                    <Icons
                      name="16px/chevron_up"
                      class="filter-icon"
                      v-if="exploreAccordion === '1'"
                    />
                    <Icons
                      name="16px/chevron_down"
                      class="filter-icon"
                      v-else
                    />
                  </template>
                  <div
                    class="item"
                    @click="
                      router.push('/'), $refs.signinDropdown.handleClose()
                    "
                  >
                    {{ $t('everything') }}
                  </div>
                  <div
                    class="item"
                    @click="
                      router.push('/experiences'),
                        $refs.signinDropdown.handleClose()
                    "
                  >
                    {{ $t('experiences') }}
                  </div>
                  <div
                    class="item"
                    @click="
                      router.push('/activities'),
                        $refs.signinDropdown.handleClose()
                    "
                  >
                    {{ $t('activities') }}
                  </div>
                  <div
                    class="item"
                    @click="
                      router.push('/tours'),
                        $refs.signinDropdown.handleClose()
                    "
                  >
                    {{ $t('tours') }}
                  </div>
                  <div
                    class="item"
                    @click="
                      router.push('/accommodations'),
                        $refs.signinDropdown.handleClose()
                    "
                  >
                    {{ $t('accommodations') }}
                  </div>
                  <div
                    class="item"
                    @click="
                      router.push('/restaurants'),
                        $refs.signinDropdown.handleClose()
                    "
                  >
                    {{ $t('food_and_drink') }}
                  </div>
                  <div class="item" @click="router.push('/destinations')">
                    {{ $t('trending_destinations') }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/your-itineraries'),
                  $refs.signinDropdown.handleClose()
              "
              ><span>{{ $t('itineraries') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/reservations'), $refs.signinDropdown.handleClose()
              "
              ><span>{{ $t('reservations') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                router.push('/donatella'), $refs.signinDropdown.handleClose()
              "
              ><span>{{ $t('donatella') }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              ><el-collapse accordion v-model="myParadiseAccordion">
                <el-collapse-item name="1">
                  <template #title>
                    <span class="body1-strong">
                      {{ $t('my_paradise') }}
                    </span>
                    <Icons
                      name="16px/chevron_up"
                      class="filter-icon"
                      v-if="myParadiseAccordion === '1'"
                    />
                    <Icons
                      name="16px/chevron_down"
                      class="filter-icon"
                      v-else
                    />
                  </template>
                  <div class="item" @click="router.push('/nft-wallet')">
                    {{ $t('nft_wallet') }}
                  </div>
                  <div class="item" @click="router.push('/account')">
                    {{ $t('account') }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-dropdown-item>
            <el-dropdown-item
              ><ElButton type="link dark" @click="onLogout()">
                {{ $t('sign_out') }}
              </ElButton>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <ElButton
        type="icon"
        :class="lightBackground ? 'light' : ''"
        v-if="width <= 1100 && isUserLogged"
        @click="showLoggedDrawer = true"
      >
        <Icons name="24px/menu" />
      </ElButton>
    </div>
    <div
      class="search-actions"
      v-if="isUserLogged && showResults && width > 1100"
    >
      <ElButton type="primary" @click="$eventBus.$emit('closeSearch')">
        {{ $t('close') }}
      </ElButton>
    </div>
    <el-drawer
      v-model="showNotLoggedDrawer"
      direction="rtl"
      class="left-drawer"
      :append-to-body="true"
      modal-class="left-drawer-modal"
    >
      <template #header>
        <img src="/logos/PALC_Marque_Black.svg" alt="logo" class="logo-img" />
      </template>
      <div class="nav-section">
        <div class="nav-item accordion">
          <el-collapse accordion v-model="exploreAccordion">
            <el-collapse-item name="1">
              <template #title>
                <span class="body1-strong">
                  {{ $t('explore') }}
                </span>
                <Icons
                  name="16px/chevron_up"
                  class="filter-icon"
                  v-if="exploreAccordion === '1'"
                />
                <Icons name="16px/chevron_down" class="filter-icon" v-else />
              </template>
              <div
                class="item"
                @click="router.push('/'), (showNotLoggedDrawer = false)"
              >
                {{ $t('everything') }}
              </div>
              <div
                class="item"
                @click="
                  router.push('/experiences'), (showNotLoggedDrawer = false)
                "
              >
                {{ $t('experiences') }}
              </div>
              <div
                class="item"
                @click="
                  router.push('/activities'), (showNotLoggedDrawer = false)
                "
              >
                {{ $t('activities') }}
              </div>
              <div
                class="item"
                @click="
                  router.push('/tours'), (showNotLoggedDrawer = false)
                "
              >
                {{ $t('tours') }}
              </div>
              <div
                class="item"
                @click="
                  router.push('/accommodations'), (showNotLoggedDrawer = false)
                "
              >
                {{ $t('accommodations') }}
              </div>
              <div
                class="item"
                @click="
                  router.push('/restaurants'), (showNotLoggedDrawer = false)
                "
              >
                {{ $t('food_and_drink') }}
              </div>
              <div
                class="item"
                @click="
                  router.push('/destinations'), (showNotLoggedDrawer = false)
                "
              >
                {{ $t('trending_destinations') }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          class="nav-item"
          @click="router.push('/donatella'), (showNotLoggedDrawer = false)"
        >
          {{ $t('donatella') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/contact-us'), (showNotLoggedDrawer = false)"
        >
          {{ $t('contact_us') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/faqs'), (showNotLoggedDrawer = false)"
        >
          {{ $t('faqs') }}
        </div>
        <div class="nav-item">
          <ElButton
            type="link dark"
            @click="router.push('/login'), (showNotLoggedDrawer = false)"
          >
            {{ $t('sign_in') }}
          </ElButton>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      v-model="showLoggedDrawer"
      direction="rtl"
      class="left-drawer"
      :append-to-body="true"
      modal-class="left-drawer-modal"
    >
      <template #header>
        <img src="/logos/PALC_Marque_Black.svg" alt="logo" class="logo-img" />
      </template>
      <div class="nav-section">
        <div class="nav-item accordion">
          <el-collapse accordion v-model="exploreAccordion">
            <el-collapse-item name="1">
              <template #title>
                <span class="body1-strong">
                  {{ $t('explore') }}
                </span>
                <Icons
                  name="16px/chevron_up"
                  class="filter-icon"
                  v-if="exploreAccordion === '1'"
                />
                <Icons name="16px/chevron_down" class="filter-icon" v-else />
              </template>
              <div
                class="item"
                @click="router.push('/'), (showLoggedDrawer = false)"
              >
                {{ $t('everything') }}
              </div>
              <div
                class="item"
                @click="router.push('/experiences'), (showLoggedDrawer = false)"
              >
                {{ $t('experiences') }}
              </div>
              <div
                class="item"
                @click="router.push('/activities'), (showLoggedDrawer = false)"
              >
                {{ $t('activities') }}
              </div>
              <div
                class="item"
                @click="
                  router.push('/accommodations'), (showLoggedDrawer = false)
                "
              >
                {{ $t('accommodations') }}
              </div>
              <div
                class="item"
                @click="router.push('/restaurants'), (showLoggedDrawer = false)"
              >
                {{ $t('food_and_drink') }}
              </div>
              <div
                class="item"
                @click="
                  router.push('/destinations'), (showLoggedDrawer = false)
                "
              >
                {{ $t('trending_destinations') }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          class="nav-item"
          @click="router.push('/your-itineraries'), (showLoggedDrawer = false)"
        >
          {{ $t('itineraries') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/reservations'), (showLoggedDrawer = false)"
        >
          {{ $t('reservations') }}
        </div>
        <div
          class="nav-item"
          @click="router.push('/donatella'), (showLoggedDrawer = false)"
        >
          {{ $t('donatella') }}
        </div>
        <div class="nav-item accordion">
          <el-collapse accordion v-model="myParadiseAccordion">
            <el-collapse-item name="1">
              <template #title>
                <span class="body1-strong">
                  {{ $t('my_paradise') }}
                </span>
                <Icons
                  name="16px/chevron_up"
                  class="filter-icon"
                  v-if="myParadiseAccordion === '1'"
                />
                <Icons name="16px/chevron_down" class="filter-icon" v-else />
              </template>
              <div
                class="item"
                @click="router.push('/nft-wallet'), (showLoggedDrawer = false)"
              >
                {{ $t('nft_wallet') }}
              </div>
              <div
                class="item"
                @click="router.push('/account'), (showLoggedDrawer = false)"
              >
                {{ $t('account') }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="nav-item">
          <ElButton
            type="link dark"
            @click="onLogout(), (showLoggedDrawer = false)"
          >
            {{ $t('sign_out') }}
          </ElButton>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import LOGOUT from '~/graphql/auth/mutation/logout.gql'

export default defineComponent({
  name: 'Header',
  props: {
    isDark: {
      type: Boolean,
      default: false
    },
    darkBackground: {
      type: Boolean,
      default: false
    },
    lightBackground: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { $eventBus } = useNuxtApp()
    const router = useRouter()
    const route = useRoute()
    const { width } = useBreakpoints()
    return {
      router,
      route,
      width,
      $eventBus
    }
  },
  data() {
    return {
      window,
      languageSelected: 'en',
      isScrolled: false,
      showNotLoggedDrawer: false,
      showLoggedDrawer: false,
      exploreAccordion: '',
      myParadiseAccordion: '',
      showResults: false
    }
  },
  computed: {
    isUserLogged() {
      return getAuthUser()?.value?.uuid ? true : false
    }
  },
  mounted() {
    if (this.isUserLogged) {
      this.languageSelected =
        getAuthUser()?.value?.language.toLowerCase() === 'es' ? 'es' : 'en'
      localStorage.setItem('languageSelected', this.languageSelected)
    } else {
      if (localStorage?.getItem('languageSelected')) {
        this.languageSelected = localStorage?.getItem('languageSelected')
      } else {
        this.languageSelected =
          this.route.fullPath === '/'
            ? 'en'
            : this.route.fullPath === '/community'
            ? 'es'
            : navigator.language.split('-')[0] === 'es'
            ? 'es'
            : 'en'
      }
    }
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        this.isScrolled = true
      } else {
        this.isScrolled = false
      }
    },
    async onLogout() {
      try {
        await mutation(LOGOUT)
        logout()
        window.location.href = '/'
      } catch (e) {
        this.$showError(e)
        console.error(e)
      }
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/typography.scss';
.logo-container {
  width: 100%;
  z-index: 20;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 139px;
  background-color: transparent !important;
  .small-logo {
    position: absolute;
    cursor: pointer;
    @media (min-width: 1100px) {
      left: 96px;
      height: 36px;
    }
    @media (max-width: 1100px) {
      height: 36px;
      left: 24px;
    }
  }
  .full-logo {
    width: 150px;
    height: 75px;
    cursor: pointer;
  }
  @media (min-width: 1100px) {
    justify-content: center;
    padding: 32px 96px;
  }
  @media (max-width: 1100px) {
    justify-content: center;
    padding: 32px 24px;
  }
  .language-selector {
    display: flex;
    position: absolute;
    cursor: pointer;
    @media (min-width: 1100px) {
      right: 330px;
      top: 77px;
      height: 24px;

      &.scrolled {
        top: 24px !important;
      }

      &.logged {
        top: 24px !important;
        right: 210px !important;
      }
    }
    @media (max-width: 1100px) {
      //
    }
    .selector {
      .body2 {
        color: var(--brand-off-black);
      }
      .body2-underline {
        color: var(--brand-off-black);
      }
    }
  }
  .login-actions {
    position: absolute;
    @media (min-width: 1100px) {
      right: 96px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 24px;
    }
    .actions {
      position: relative;
      top: 36px;
      span {
        cursor: pointer;
      }
      .el-button {
        margin-left: 24px !important;
      }
    }
  }
  .donatella-actions {
    position: absolute;
    @media (min-width: 1100px) {
      right: 152px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 72px;
    }
    display: flex;
    align-items: center;
  }
  .burguer-actions {
    position: absolute;
    @media (min-width: 1100px) {
      right: 96px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 24px;
    }
    display: flex;
    align-items: center;
  }

  .search-actions {
    position: absolute;
    @media (min-width: 1100px) {
      right: 96px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 24px;
    }
    display: flex;
    align-items: center;
    .el-button {
      @media (min-width: 1100px) {
        width: 97px;
      }
    }
  }

  &.dark {
    .language-selector {
      .selector {
        .body2 {
          color: var(--brand-white);
        }
        .body2-underline {
          color: var(--brand-white);
        }
      }
    }
    .login-actions {
      .actions {
        span {
          color: var(--brand-white);
        }
      }
    }
  }

  &.dark-background {
    background-color: var(--brand-off-black) !important;
    backdrop-filter: blur(7.5px);
  }

  &.light-background {
    background-color: var(--brand-white) !important;
  }

  &.logged {
    height: 68px;
    @media (min-width: 1100px) {
      padding: 16px 96px;
    }
    @media (max-width: 1100px) {
      padding: 16px 24px;
    }
  }

  &.scrolled {
    height: 68px;
    @media (min-width: 1100px) {
      padding: 16px 96px;
    }
    @media (max-width: 1100px) {
      padding: 16px 24px;
    }
    background-color: var(--brand-off-black) !important;
    backdrop-filter: blur(7.5px);
    .login-actions {
      .actions {
        top: 18px !important;
        span {
          color: var(--brand-white);
        }
      }
    }
    .language-selector {
      .selector {
        .body2 {
          color: var(--brand-white);
        }
        .body2-underline {
          color: var(--brand-white);
        }
      }
    }
  }
}

.left-drawer-modal {
  .left-drawer {
    .el-drawer__header {
      img {
        position: absolute;
        top: 24px;
        left: 24px;
        height: 36px;
      }
    }
    .el-drawer__body {
      margin-top: 32px;
      .login-section {
        padding: 40px 24px 40px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .el-button {
          &:nth-of-type(2) {
            margin-top: 28px;
          }
        }
      }
      .navigation-section {
        padding: 16px 24px;
        .body1-strong {
          display: block;
          padding: 12px 0;
        }
      }
      .nav-section {
        padding: 16px 0 16px 0;
        .nav-item {
          @extend .body1-strong;
          color: var(--brand-off-black);
          &:not(.accordion) {
            height: 42px;
            display: flex;
            align-items: center;
          }

          :deep(.el-collapse) {
            width: 100%;
            border: 0 !important;
            .el-collapse-item {
              border: 0 !important;
              .el-collapse-item__header {
                background-color: var(--brand-white) !important;
                border: 0 !important;
                position: relative;
                text-align: left;
                height: 48px;
                i.el-icon {
                  svg {
                    display: none;
                  }
                }
                i.filter-icon {
                  position: absolute;
                  right: 0 !important;
                }
              }
              .el-collapse-item__wrap {
                margin: 0px 8px 8px 32px;
                border-bottom: 1px solid var(--brand-white) !important;
                .el-collapse-item__content {
                  background-color: var(--brand-white) !important;
                  padding-top: 4px;
                  padding-bottom: 0 !important;
                  .item {
                    @extend .body2;
                    &:not(:first-of-type) {
                      margin-top: 16px;
                    }
                  }
                }
              }
            }
          }

          &:last-of-type {
            padding: 40px 0 0 0 !important;
          }
        }
      }
    }
  }
}

.el-dropdown {
  :deep(.signin-dropdown) {
    border-radius: 20px !important;
    box-shadow: 0 0 0 0 !important;
    width: 200px;
    inset: 0px auto auto -159px !important;
    .el-scrollbar {
      border-radius: 20px !important;
      box-shadow: 0 0 0 0 !important;
      .el-dropdown__list {
        .el-dropdown-menu {
          padding: 0 0 40px 0;
          .el-dropdown-menu__item {
            padding: 4px 24px;
            width: 100%;
            &:nth-of-type(1) {
              height: 32px;
              &:hover {
                background-color: var(--brand-white) !important;
              }
            }
            @extend .body1-strong;
            color: var(--brand-off-black) !important;
            .el-collapse {
              width: 160px;
              border: 0 !important;
              .el-collapse-item {
                border: 0 !important;
                .el-collapse-item__header {
                  background-color: var(--brand-white) !important;
                  border: 0 !important;
                  position: relative;
                  text-align: left;
                  height: 24px;
                  i.el-icon {
                    svg {
                      display: none;
                    }
                  }
                  i.filter-icon {
                    position: absolute;
                    right: 0 !important;
                  }
                }
                .el-collapse-item__wrap {
                  padding: 0px 0px 8px 12px;
                  border-bottom: 1px solid var(--brand-white) !important;
                  .el-collapse-item__content {
                    background-color: var(--brand-white) !important;
                    padding-top: 4px;
                    padding-bottom: 0 !important;
                    .item {
                      @extend .body2;
                      &:not(:first-of-type) {
                        margin-top: 8px;
                      }
                    }
                  }
                }
              }
            }
            .icon-close {
              position: absolute;
              right: 2px;
              top: 6px;
            }

            &:hover,
            &:focus {
              background-color: var(--brand-off-white) !important;
              color: var(--brand-off-black) !important;
              .el-collapse {
                .el-collapse-item {
                  .el-collapse-item__header {
                    background-color: var(--brand-off-white) !important;
                  }
                  .el-collapse-item__wrap {
                    background-color: var(--brand-off-white) !important;
                    .el-collapse-item__content {
                      background-color: var(--brand-off-white) !important;
                    }
                  }
                }
              }
            }

            &:last-of-type {
              padding: 16px 24px 0px 24px !important;
              &:hover {
                background-color: var(--brand-white) !important;
              }
            }
          }
          .el-dropdown-menu__item--divided {
            display: none;
          }
        }
      }
    }
    .el-popper__arrow {
      display: none;
    }
  }
}
</style>
