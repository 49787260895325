import { default as indexA6CkBJ9gD7Meta } from "/__w/landing-page/landing-page/pages/about-us/index.vue?macro=true";
import { default as indexEM211K2zS6Meta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/booked/index.vue?macro=true";
import { default as indexSvvPPaDEsYMeta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/confirm-booking/index.vue?macro=true";
import { default as room_45typesL6iEgJeU6EMeta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/confirm-booking/room-types.vue?macro=true";
import { default as indexzRSeNrpqWQMeta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/index.vue?macro=true";
import { default as indexkBQnKs7X59Meta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/payment/index.vue?macro=true";
import { default as indexOh8KX4Gi5EMeta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/success/index.vue?macro=true";
import { default as indexo8MiGLJIwpMeta } from "/__w/landing-page/landing-page/pages/accommodations/index.vue?macro=true";
import { default as indexQWTfd6C61KMeta } from "/__w/landing-page/landing-page/pages/account/index.vue?macro=true";
import { default as indexOaCdqv0UnnMeta } from "/__w/landing-page/landing-page/pages/activities/[id]/booked/index.vue?macro=true";
import { default as indexn4yh0WphGFMeta } from "/__w/landing-page/landing-page/pages/activities/[id]/confirm-booking/index.vue?macro=true";
import { default as indexREkxgbTVd0Meta } from "/__w/landing-page/landing-page/pages/activities/[id]/index.vue?macro=true";
import { default as indexa4TrHMsfftMeta } from "/__w/landing-page/landing-page/pages/activities/[id]/payment/index.vue?macro=true";
import { default as indexpAvpVVXWYrMeta } from "/__w/landing-page/landing-page/pages/activities/[id]/success/index.vue?macro=true";
import { default as indexXHXZeRuJA5Meta } from "/__w/landing-page/landing-page/pages/activities/index.vue?macro=true";
import { default as forgot_45passwordjkAmvAWroOMeta } from "/__w/landing-page/landing-page/pages/auth/forgot-password.vue?macro=true";
import { default as register6FpZsYOtmdMeta } from "/__w/landing-page/landing-page/pages/auth/register.vue?macro=true";
import { default as reset_45passwordotBAwKl35NMeta } from "/__w/landing-page/landing-page/pages/auth/reset-password.vue?macro=true";
import { default as validate_45emailc3uObsadH8Meta } from "/__w/landing-page/landing-page/pages/auth/validate-email.vue?macro=true";
import { default as validate_45phone3ATy3zcvWrMeta } from "/__w/landing-page/landing-page/pages/auth/validate-phone.vue?macro=true";
import { default as indexUcDTjTUthwMeta } from "/__w/landing-page/landing-page/pages/bye/index.vue?macro=true";
import { default as indexzjPi8hX5lZMeta } from "/__w/landing-page/landing-page/pages/community/index.vue?macro=true";
import { default as indexr8bCY4aDPWMeta } from "/__w/landing-page/landing-page/pages/contact-us/index.vue?macro=true";
import { default as index4lH3SXID4XMeta } from "/__w/landing-page/landing-page/pages/cookies/index.vue?macro=true";
import { default as indexwj1zLhUy00Meta } from "/__w/landing-page/landing-page/pages/destinations/[id]/index.vue?macro=true";
import { default as indexTw4dUykH0bMeta } from "/__w/landing-page/landing-page/pages/destinations/index.vue?macro=true";
import { default as indexChFVAVuhdIMeta } from "/__w/landing-page/landing-page/pages/donatella/index.vue?macro=true";
import { default as indexvUGuDSmPdXMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/booked/index.vue?macro=true";
import { default as indexF25A07E93PMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/confirm-booking/index.vue?macro=true";
import { default as indexsI9aLfH4jdMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/index.vue?macro=true";
import { default as indexWSuVgpJLkcMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/payment/index.vue?macro=true";
import { default as indexMeHK8O0GvGMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/success/index.vue?macro=true";
import { default as indexS953jUJuR6Meta } from "/__w/landing-page/landing-page/pages/experiences/index.vue?macro=true";
import { default as indexy1Aky82SwkMeta } from "/__w/landing-page/landing-page/pages/faqs/index.vue?macro=true";
import { default as index6WHDQYTyszMeta } from "/__w/landing-page/landing-page/pages/guides/[id]/index.vue?macro=true";
import { default as indexBG1OrYfAh6Meta } from "/__w/landing-page/landing-page/pages/index.vue?macro=true";
import { default as indexGCmocRIYBGMeta } from "/__w/landing-page/landing-page/pages/login/index.vue?macro=true";
import { default as indexyqmPn2aJLZMeta } from "/__w/landing-page/landing-page/pages/nft-wallet/[id]/index.vue?macro=true";
import { default as indexaPcDpJEtkEMeta } from "/__w/landing-page/landing-page/pages/nft-wallet/index.vue?macro=true";
import { default as indexH8bv50T0F0Meta } from "/__w/landing-page/landing-page/pages/privacy/index.vue?macro=true";
import { default as indexbouK4vAWecMeta } from "/__w/landing-page/landing-page/pages/reservations/[id]/index.vue?macro=true";
import { default as indexHdA7AEhMwIMeta } from "/__w/landing-page/landing-page/pages/reservations/index.vue?macro=true";
import { default as indexNvc2zxVxMhMeta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/booked/index.vue?macro=true";
import { default as index4hsbEjdSw5Meta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/confirm-booking/index.vue?macro=true";
import { default as indexibC4Sej6SrMeta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/index.vue?macro=true";
import { default as indexGjN0hJ4VesMeta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/payment/index.vue?macro=true";
import { default as index9Kin83lMQXMeta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/success/index.vue?macro=true";
import { default as indexMVOkvPSCanMeta } from "/__w/landing-page/landing-page/pages/restaurants/index.vue?macro=true";
import { default as index5y77e46riYMeta } from "/__w/landing-page/landing-page/pages/search/index.vue?macro=true";
import { default as indexvaVHIaF6AeMeta } from "/__w/landing-page/landing-page/pages/suggestions/index.vue?macro=true";
import { default as indexFyFQhQCIMQMeta } from "/__w/landing-page/landing-page/pages/surveys/guide/index.vue?macro=true";
import { default as indexIztRmgCvLvMeta } from "/__w/landing-page/landing-page/pages/surveys/traveller/index.vue?macro=true";
import { default as indexEWLHdA3KyCMeta } from "/__w/landing-page/landing-page/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexMbUrXPxpCUMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/booked/index.vue?macro=true";
import { default as indextkTT7K6XlfMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/confirm-booking/index.vue?macro=true";
import { default as indexu2q6NgycJxMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/index.vue?macro=true";
import { default as index7a2ceSnCAJMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/payment/index.vue?macro=true";
import { default as index0AkeSiK03CMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/success/index.vue?macro=true";
import { default as indexopbspky32tMeta } from "/__w/landing-page/landing-page/pages/tours/index.vue?macro=true";
import { default as indexqtm2giEJgeMeta } from "/__w/landing-page/landing-page/pages/your-itineraries/[id]/index.vue?macro=true";
import { default as indexikuCWdka1aMeta } from "/__w/landing-page/landing-page/pages/your-itineraries/index.vue?macro=true";
export default [
  {
    name: indexA6CkBJ9gD7Meta?.name ?? "about-us",
    path: indexA6CkBJ9gD7Meta?.path ?? "/about-us",
    meta: indexA6CkBJ9gD7Meta || {},
    alias: indexA6CkBJ9gD7Meta?.alias || [],
    redirect: indexA6CkBJ9gD7Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexEM211K2zS6Meta?.name ?? "accommodations-id-booked",
    path: indexEM211K2zS6Meta?.path ?? "/accommodations/:id()/booked",
    meta: indexEM211K2zS6Meta || {},
    alias: indexEM211K2zS6Meta?.alias || [],
    redirect: indexEM211K2zS6Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/booked/index.vue").then(m => m.default || m)
  },
  {
    name: indexSvvPPaDEsYMeta?.name ?? "accommodations-id-confirm-booking",
    path: indexSvvPPaDEsYMeta?.path ?? "/accommodations/:id()/confirm-booking",
    meta: indexSvvPPaDEsYMeta || {},
    alias: indexSvvPPaDEsYMeta?.alias || [],
    redirect: indexSvvPPaDEsYMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/confirm-booking/index.vue").then(m => m.default || m)
  },
  {
    name: room_45typesL6iEgJeU6EMeta?.name ?? "accommodations-id-confirm-booking-room-types",
    path: room_45typesL6iEgJeU6EMeta?.path ?? "/accommodations/:id()/confirm-booking/room-types",
    meta: room_45typesL6iEgJeU6EMeta || {},
    alias: room_45typesL6iEgJeU6EMeta?.alias || [],
    redirect: room_45typesL6iEgJeU6EMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/confirm-booking/room-types.vue").then(m => m.default || m)
  },
  {
    name: indexzRSeNrpqWQMeta?.name ?? "accommodations-id",
    path: indexzRSeNrpqWQMeta?.path ?? "/accommodations/:id()",
    meta: indexzRSeNrpqWQMeta || {},
    alias: indexzRSeNrpqWQMeta?.alias || [],
    redirect: indexzRSeNrpqWQMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkBQnKs7X59Meta?.name ?? "accommodations-id-payment",
    path: indexkBQnKs7X59Meta?.path ?? "/accommodations/:id()/payment",
    meta: indexkBQnKs7X59Meta || {},
    alias: indexkBQnKs7X59Meta?.alias || [],
    redirect: indexkBQnKs7X59Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexOh8KX4Gi5EMeta?.name ?? "accommodations-id-success",
    path: indexOh8KX4Gi5EMeta?.path ?? "/accommodations/:id()/success",
    meta: indexOh8KX4Gi5EMeta || {},
    alias: indexOh8KX4Gi5EMeta?.alias || [],
    redirect: indexOh8KX4Gi5EMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexo8MiGLJIwpMeta?.name ?? "accommodations",
    path: indexo8MiGLJIwpMeta?.path ?? "/accommodations",
    meta: indexo8MiGLJIwpMeta || {},
    alias: indexo8MiGLJIwpMeta?.alias || [],
    redirect: indexo8MiGLJIwpMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/index.vue").then(m => m.default || m)
  },
  {
    name: indexQWTfd6C61KMeta?.name ?? "account",
    path: indexQWTfd6C61KMeta?.path ?? "/account",
    meta: indexQWTfd6C61KMeta || {},
    alias: indexQWTfd6C61KMeta?.alias || [],
    redirect: indexQWTfd6C61KMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexOaCdqv0UnnMeta?.name ?? "activities-id-booked",
    path: indexOaCdqv0UnnMeta?.path ?? "/activities/:id()/booked",
    meta: indexOaCdqv0UnnMeta || {},
    alias: indexOaCdqv0UnnMeta?.alias || [],
    redirect: indexOaCdqv0UnnMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/booked/index.vue").then(m => m.default || m)
  },
  {
    name: indexn4yh0WphGFMeta?.name ?? "activities-id-confirm-booking",
    path: indexn4yh0WphGFMeta?.path ?? "/activities/:id()/confirm-booking",
    meta: indexn4yh0WphGFMeta || {},
    alias: indexn4yh0WphGFMeta?.alias || [],
    redirect: indexn4yh0WphGFMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/confirm-booking/index.vue").then(m => m.default || m)
  },
  {
    name: indexREkxgbTVd0Meta?.name ?? "activities-id",
    path: indexREkxgbTVd0Meta?.path ?? "/activities/:id()",
    meta: indexREkxgbTVd0Meta || {},
    alias: indexREkxgbTVd0Meta?.alias || [],
    redirect: indexREkxgbTVd0Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4TrHMsfftMeta?.name ?? "activities-id-payment",
    path: indexa4TrHMsfftMeta?.path ?? "/activities/:id()/payment",
    meta: indexa4TrHMsfftMeta || {},
    alias: indexa4TrHMsfftMeta?.alias || [],
    redirect: indexa4TrHMsfftMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexpAvpVVXWYrMeta?.name ?? "activities-id-success",
    path: indexpAvpVVXWYrMeta?.path ?? "/activities/:id()/success",
    meta: indexpAvpVVXWYrMeta || {},
    alias: indexpAvpVVXWYrMeta?.alias || [],
    redirect: indexpAvpVVXWYrMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexXHXZeRuJA5Meta?.name ?? "activities",
    path: indexXHXZeRuJA5Meta?.path ?? "/activities",
    meta: indexXHXZeRuJA5Meta || {},
    alias: indexXHXZeRuJA5Meta?.alias || [],
    redirect: indexXHXZeRuJA5Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/activities/index.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordjkAmvAWroOMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordjkAmvAWroOMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordjkAmvAWroOMeta || {},
    alias: forgot_45passwordjkAmvAWroOMeta?.alias || [],
    redirect: forgot_45passwordjkAmvAWroOMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: register6FpZsYOtmdMeta?.name ?? "auth-register",
    path: register6FpZsYOtmdMeta?.path ?? "/auth/register",
    meta: register6FpZsYOtmdMeta || {},
    alias: register6FpZsYOtmdMeta?.alias || [],
    redirect: register6FpZsYOtmdMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordotBAwKl35NMeta?.name ?? "auth-reset-password",
    path: reset_45passwordotBAwKl35NMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordotBAwKl35NMeta || {},
    alias: reset_45passwordotBAwKl35NMeta?.alias || [],
    redirect: reset_45passwordotBAwKl35NMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: validate_45emailc3uObsadH8Meta?.name ?? "auth-validate-email",
    path: validate_45emailc3uObsadH8Meta?.path ?? "/auth/validate-email",
    meta: validate_45emailc3uObsadH8Meta || {},
    alias: validate_45emailc3uObsadH8Meta?.alias || [],
    redirect: validate_45emailc3uObsadH8Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/validate-email.vue").then(m => m.default || m)
  },
  {
    name: validate_45phone3ATy3zcvWrMeta?.name ?? "auth-validate-phone",
    path: validate_45phone3ATy3zcvWrMeta?.path ?? "/auth/validate-phone",
    meta: validate_45phone3ATy3zcvWrMeta || {},
    alias: validate_45phone3ATy3zcvWrMeta?.alias || [],
    redirect: validate_45phone3ATy3zcvWrMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/validate-phone.vue").then(m => m.default || m)
  },
  {
    name: indexUcDTjTUthwMeta?.name ?? "bye",
    path: indexUcDTjTUthwMeta?.path ?? "/bye",
    meta: indexUcDTjTUthwMeta || {},
    alias: indexUcDTjTUthwMeta?.alias || [],
    redirect: indexUcDTjTUthwMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/bye/index.vue").then(m => m.default || m)
  },
  {
    name: indexzjPi8hX5lZMeta?.name ?? "community",
    path: indexzjPi8hX5lZMeta?.path ?? "/community",
    meta: indexzjPi8hX5lZMeta || {},
    alias: indexzjPi8hX5lZMeta?.alias || [],
    redirect: indexzjPi8hX5lZMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: indexr8bCY4aDPWMeta?.name ?? "contact-us",
    path: indexr8bCY4aDPWMeta?.path ?? "/contact-us",
    meta: indexr8bCY4aDPWMeta || {},
    alias: indexr8bCY4aDPWMeta?.alias || [],
    redirect: indexr8bCY4aDPWMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: index4lH3SXID4XMeta?.name ?? "cookies",
    path: index4lH3SXID4XMeta?.path ?? "/cookies",
    meta: index4lH3SXID4XMeta || {},
    alias: index4lH3SXID4XMeta?.alias || [],
    redirect: index4lH3SXID4XMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexwj1zLhUy00Meta?.name ?? "destinations-id",
    path: indexwj1zLhUy00Meta?.path ?? "/destinations/:id()",
    meta: indexwj1zLhUy00Meta || {},
    alias: indexwj1zLhUy00Meta?.alias || [],
    redirect: indexwj1zLhUy00Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/destinations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTw4dUykH0bMeta?.name ?? "destinations",
    path: indexTw4dUykH0bMeta?.path ?? "/destinations",
    meta: indexTw4dUykH0bMeta || {},
    alias: indexTw4dUykH0bMeta?.alias || [],
    redirect: indexTw4dUykH0bMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/destinations/index.vue").then(m => m.default || m)
  },
  {
    name: indexChFVAVuhdIMeta?.name ?? "donatella",
    path: indexChFVAVuhdIMeta?.path ?? "/donatella",
    meta: indexChFVAVuhdIMeta || {},
    alias: indexChFVAVuhdIMeta?.alias || [],
    redirect: indexChFVAVuhdIMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/donatella/index.vue").then(m => m.default || m)
  },
  {
    name: indexvUGuDSmPdXMeta?.name ?? "experiences-id-booked",
    path: indexvUGuDSmPdXMeta?.path ?? "/experiences/:id()/booked",
    meta: indexvUGuDSmPdXMeta || {},
    alias: indexvUGuDSmPdXMeta?.alias || [],
    redirect: indexvUGuDSmPdXMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/booked/index.vue").then(m => m.default || m)
  },
  {
    name: indexF25A07E93PMeta?.name ?? "experiences-id-confirm-booking",
    path: indexF25A07E93PMeta?.path ?? "/experiences/:id()/confirm-booking",
    meta: indexF25A07E93PMeta || {},
    alias: indexF25A07E93PMeta?.alias || [],
    redirect: indexF25A07E93PMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/confirm-booking/index.vue").then(m => m.default || m)
  },
  {
    name: indexsI9aLfH4jdMeta?.name ?? "experiences-id",
    path: indexsI9aLfH4jdMeta?.path ?? "/experiences/:id()",
    meta: indexsI9aLfH4jdMeta || {},
    alias: indexsI9aLfH4jdMeta?.alias || [],
    redirect: indexsI9aLfH4jdMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWSuVgpJLkcMeta?.name ?? "experiences-id-payment",
    path: indexWSuVgpJLkcMeta?.path ?? "/experiences/:id()/payment",
    meta: indexWSuVgpJLkcMeta || {},
    alias: indexWSuVgpJLkcMeta?.alias || [],
    redirect: indexWSuVgpJLkcMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexMeHK8O0GvGMeta?.name ?? "experiences-id-success",
    path: indexMeHK8O0GvGMeta?.path ?? "/experiences/:id()/success",
    meta: indexMeHK8O0GvGMeta || {},
    alias: indexMeHK8O0GvGMeta?.alias || [],
    redirect: indexMeHK8O0GvGMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexS953jUJuR6Meta?.name ?? "experiences",
    path: indexS953jUJuR6Meta?.path ?? "/experiences",
    meta: indexS953jUJuR6Meta || {},
    alias: indexS953jUJuR6Meta?.alias || [],
    redirect: indexS953jUJuR6Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/experiences/index.vue").then(m => m.default || m)
  },
  {
    name: indexy1Aky82SwkMeta?.name ?? "faqs",
    path: indexy1Aky82SwkMeta?.path ?? "/faqs",
    meta: indexy1Aky82SwkMeta || {},
    alias: indexy1Aky82SwkMeta?.alias || [],
    redirect: indexy1Aky82SwkMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: index6WHDQYTyszMeta?.name ?? "guides-id",
    path: index6WHDQYTyszMeta?.path ?? "/guides/:id()",
    meta: index6WHDQYTyszMeta || {},
    alias: index6WHDQYTyszMeta?.alias || [],
    redirect: index6WHDQYTyszMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/guides/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBG1OrYfAh6Meta?.name ?? "index",
    path: indexBG1OrYfAh6Meta?.path ?? "/",
    meta: indexBG1OrYfAh6Meta || {},
    alias: indexBG1OrYfAh6Meta?.alias || [],
    redirect: indexBG1OrYfAh6Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexGCmocRIYBGMeta?.name ?? "login",
    path: indexGCmocRIYBGMeta?.path ?? "/login",
    meta: indexGCmocRIYBGMeta || {},
    alias: indexGCmocRIYBGMeta?.alias || [],
    redirect: indexGCmocRIYBGMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexyqmPn2aJLZMeta?.name ?? "nft-wallet-id",
    path: indexyqmPn2aJLZMeta?.path ?? "/nft-wallet/:id()",
    meta: indexyqmPn2aJLZMeta || {},
    alias: indexyqmPn2aJLZMeta?.alias || [],
    redirect: indexyqmPn2aJLZMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/nft-wallet/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexaPcDpJEtkEMeta?.name ?? "nft-wallet",
    path: indexaPcDpJEtkEMeta?.path ?? "/nft-wallet",
    meta: indexaPcDpJEtkEMeta || {},
    alias: indexaPcDpJEtkEMeta?.alias || [],
    redirect: indexaPcDpJEtkEMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/nft-wallet/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8bv50T0F0Meta?.name ?? "privacy",
    path: indexH8bv50T0F0Meta?.path ?? "/privacy",
    meta: indexH8bv50T0F0Meta || {},
    alias: indexH8bv50T0F0Meta?.alias || [],
    redirect: indexH8bv50T0F0Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexbouK4vAWecMeta?.name ?? "reservations-id",
    path: indexbouK4vAWecMeta?.path ?? "/reservations/:id()",
    meta: indexbouK4vAWecMeta || {},
    alias: indexbouK4vAWecMeta?.alias || [],
    redirect: indexbouK4vAWecMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/reservations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHdA7AEhMwIMeta?.name ?? "reservations",
    path: indexHdA7AEhMwIMeta?.path ?? "/reservations",
    meta: indexHdA7AEhMwIMeta || {},
    alias: indexHdA7AEhMwIMeta?.alias || [],
    redirect: indexHdA7AEhMwIMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: indexNvc2zxVxMhMeta?.name ?? "restaurants-id-booked",
    path: indexNvc2zxVxMhMeta?.path ?? "/restaurants/:id()/booked",
    meta: indexNvc2zxVxMhMeta || {},
    alias: indexNvc2zxVxMhMeta?.alias || [],
    redirect: indexNvc2zxVxMhMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/booked/index.vue").then(m => m.default || m)
  },
  {
    name: index4hsbEjdSw5Meta?.name ?? "restaurants-id-confirm-booking",
    path: index4hsbEjdSw5Meta?.path ?? "/restaurants/:id()/confirm-booking",
    meta: index4hsbEjdSw5Meta || {},
    alias: index4hsbEjdSw5Meta?.alias || [],
    redirect: index4hsbEjdSw5Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/confirm-booking/index.vue").then(m => m.default || m)
  },
  {
    name: indexibC4Sej6SrMeta?.name ?? "restaurants-id",
    path: indexibC4Sej6SrMeta?.path ?? "/restaurants/:id()",
    meta: indexibC4Sej6SrMeta || {},
    alias: indexibC4Sej6SrMeta?.alias || [],
    redirect: indexibC4Sej6SrMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGjN0hJ4VesMeta?.name ?? "restaurants-id-payment",
    path: indexGjN0hJ4VesMeta?.path ?? "/restaurants/:id()/payment",
    meta: indexGjN0hJ4VesMeta || {},
    alias: indexGjN0hJ4VesMeta?.alias || [],
    redirect: indexGjN0hJ4VesMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/payment/index.vue").then(m => m.default || m)
  },
  {
    name: index9Kin83lMQXMeta?.name ?? "restaurants-id-success",
    path: index9Kin83lMQXMeta?.path ?? "/restaurants/:id()/success",
    meta: index9Kin83lMQXMeta || {},
    alias: index9Kin83lMQXMeta?.alias || [],
    redirect: index9Kin83lMQXMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexMVOkvPSCanMeta?.name ?? "restaurants",
    path: indexMVOkvPSCanMeta?.path ?? "/restaurants",
    meta: indexMVOkvPSCanMeta || {},
    alias: indexMVOkvPSCanMeta?.alias || [],
    redirect: indexMVOkvPSCanMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: index5y77e46riYMeta?.name ?? "search",
    path: index5y77e46riYMeta?.path ?? "/search",
    meta: index5y77e46riYMeta || {},
    alias: index5y77e46riYMeta?.alias || [],
    redirect: index5y77e46riYMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexvaVHIaF6AeMeta?.name ?? "suggestions",
    path: indexvaVHIaF6AeMeta?.path ?? "/suggestions",
    meta: indexvaVHIaF6AeMeta || {},
    alias: indexvaVHIaF6AeMeta?.alias || [],
    redirect: indexvaVHIaF6AeMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/suggestions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFyFQhQCIMQMeta?.name ?? "surveys-guide",
    path: indexFyFQhQCIMQMeta?.path ?? "/surveys/guide",
    meta: indexFyFQhQCIMQMeta || {},
    alias: indexFyFQhQCIMQMeta?.alias || [],
    redirect: indexFyFQhQCIMQMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/surveys/guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexIztRmgCvLvMeta?.name ?? "surveys-traveller",
    path: indexIztRmgCvLvMeta?.path ?? "/surveys/traveller",
    meta: indexIztRmgCvLvMeta || {},
    alias: indexIztRmgCvLvMeta?.alias || [],
    redirect: indexIztRmgCvLvMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/surveys/traveller/index.vue").then(m => m.default || m)
  },
  {
    name: indexEWLHdA3KyCMeta?.name ?? "terms-and-conditions",
    path: indexEWLHdA3KyCMeta?.path ?? "/terms-and-conditions",
    meta: indexEWLHdA3KyCMeta || {},
    alias: indexEWLHdA3KyCMeta?.alias || [],
    redirect: indexEWLHdA3KyCMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbUrXPxpCUMeta?.name ?? "tours-id-booked",
    path: indexMbUrXPxpCUMeta?.path ?? "/tours/:id()/booked",
    meta: indexMbUrXPxpCUMeta || {},
    alias: indexMbUrXPxpCUMeta?.alias || [],
    redirect: indexMbUrXPxpCUMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/booked/index.vue").then(m => m.default || m)
  },
  {
    name: indextkTT7K6XlfMeta?.name ?? "tours-id-confirm-booking",
    path: indextkTT7K6XlfMeta?.path ?? "/tours/:id()/confirm-booking",
    meta: indextkTT7K6XlfMeta || {},
    alias: indextkTT7K6XlfMeta?.alias || [],
    redirect: indextkTT7K6XlfMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/confirm-booking/index.vue").then(m => m.default || m)
  },
  {
    name: indexu2q6NgycJxMeta?.name ?? "tours-id",
    path: indexu2q6NgycJxMeta?.path ?? "/tours/:id()",
    meta: indexu2q6NgycJxMeta || {},
    alias: indexu2q6NgycJxMeta?.alias || [],
    redirect: indexu2q6NgycJxMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index7a2ceSnCAJMeta?.name ?? "tours-id-payment",
    path: index7a2ceSnCAJMeta?.path ?? "/tours/:id()/payment",
    meta: index7a2ceSnCAJMeta || {},
    alias: index7a2ceSnCAJMeta?.alias || [],
    redirect: index7a2ceSnCAJMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/payment/index.vue").then(m => m.default || m)
  },
  {
    name: index0AkeSiK03CMeta?.name ?? "tours-id-success",
    path: index0AkeSiK03CMeta?.path ?? "/tours/:id()/success",
    meta: index0AkeSiK03CMeta || {},
    alias: index0AkeSiK03CMeta?.alias || [],
    redirect: index0AkeSiK03CMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexopbspky32tMeta?.name ?? "tours",
    path: indexopbspky32tMeta?.path ?? "/tours",
    meta: indexopbspky32tMeta || {},
    alias: indexopbspky32tMeta?.alias || [],
    redirect: indexopbspky32tMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexqtm2giEJgeMeta?.name ?? "your-itineraries-id",
    path: indexqtm2giEJgeMeta?.path ?? "/your-itineraries/:id()",
    meta: indexqtm2giEJgeMeta || {},
    alias: indexqtm2giEJgeMeta?.alias || [],
    redirect: indexqtm2giEJgeMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/your-itineraries/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexikuCWdka1aMeta?.name ?? "your-itineraries",
    path: indexikuCWdka1aMeta?.path ?? "/your-itineraries",
    meta: indexikuCWdka1aMeta || {},
    alias: indexikuCWdka1aMeta?.alias || [],
    redirect: indexikuCWdka1aMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/your-itineraries/index.vue").then(m => m.default || m)
  }
]