import { provideApolloClient } from '@vue/apollo-composable'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { createUploadLink } from 'apollo-upload-client'

export function apolloClient() {
    const config = useRuntimeConfig()

    const httpLink = createUploadLink({
        // You should use an absolute URL here
        uri: config.public.httpEndpoint,
        headers: $cookies.get('apollo-token')
            ? {
                Authorization: `Bearer ${$cookies.get('apollo-token')}`
            }
            : {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                'Access-Control-Expose-Headers': '*'
            }
    })

    const apolloClient = new ApolloClient({
        // Provide the URL to the API server.
        link: httpLink,

        // Using a cache for fast subsequent queries.
        cache: new InMemoryCache()
    })

    provideApolloClient(apolloClient)
}